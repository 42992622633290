<template>
  <b-modal
    ref="product-stock-excel"
    hide-footer
    :title="titleExcel"
    id="product-stock-excel"
  >
    <b-row class="mb-4">
      <b-col cols="12">
        <b-form-group label="Chọn loại tồn kho cần xuất">
          <b-form-checkbox-group
            v-model="selected"
            :options="options"
            name="stock-type-group"
          ></b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-overlay
      :show="isDownloading"
      rounded
      opacity="0.6"
      class="d-inline-block mr-3"
      @hidden="$refs.button.focus()"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner
            variant="light"
            small
            type="grow"
          ></b-spinner>
        </div>
      </template>
      <b-button
        variant="primary"
        size="sm"
        @click="exportToExcelStream"
      >
        <v-icon
          small
          class="text-white"
          v-b-tooltip
        >mdi-download</v-icon>
        <strong>Xuất Excel</strong>
      </b-button>
    </b-overlay>

    <b-button
      style="width: 80px"
      variant="secondary"
      size="sm"
      @click="$bvModal.hide('product-stock-excel')"
    >
      <strong>Hủy</strong>
    </b-button>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { checkPermissions } from '@/utils/saveDataToLocal';
import { makeToastFaile } from '@/utils/common';
import { BASE_URL } from '@/utils/constants';
import axios from 'axios';
import fileDownload from '@/utils/file-download';
export default {
  props: ['param'],
  mixins: [validationMixin],
  data() {
    return {
      titleExcel: 'Xuất excel tồn kho sản phẩm',
      types: [],
      isDownloading: false,
      options: [
        { text: 'Tồn có thể bán', value: 1 },
        { text: 'Đang chuyển kho', value: 2 },
        { text: 'Đang giữ hàng', value: 3 },
        { text: 'Đang vận chuyển', value: 4 },
        { text: 'Đang bảo hành', value: 5 },
      ],
      selected: [],
    };
  },
  created() {
    this.selected.push(1);
  },
  watch: {
    $props: {
      handler() {
        this.apiParams = { ...this.param };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    checkPermissions,
    exportToExcelStream() {
      if (!this.selected.length) {
        return makeToastFaile('Vui lòng chọn loại tồn cần xuất');
      }

      const baseUrl = BASE_URL;
      this.isDownloading = true;
      const params = { ...this.param, ...{ inventoryTypes: this.selected } };

      axios
        .get(`${baseUrl}productStock/export-excel`, {
          params: params,
          responseType: 'blob',
        })
        .then((response) => {
          const fileName = fileDownload.getFileName(response);
          fileDownload.createFile(response.data, fileName);
          this.isDownloading = false;
        })
        .catch((err) => {
          this.isDownloading = false;
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }

          this.$nprogress.done();
        });
    },
  },
};
</script>
